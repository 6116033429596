<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Permission Group Details</h5>

            <div class="col-md-12 mb-2">
              <label for="permissionGroupName" class="form-label">Permission Group Name</label>
              <input type="text" class="form-control" id="permissionGroupName" v-model="permissionGroup.name">
            </div>
            <div class="col-md-12 mb-2">
              <label for="permissionGroupDescription" class="form-label">Permission Group Description</label>
              <input type="text" class="form-control" id="permissionGroupDescription" v-model="permissionGroup.description">
            </div>

            <div class="row">
              <div class="col-md-12">
                <input type="button" style="float: right;" class="btn btn-light px-4" value="Save Changes">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Permission Group Permissions</h5>

            <div class="row">
              <div class="col-6">
                <p style="font-weight: bold;">Available Permissions</p>
                <table id="example" class="table table-hover" style="width:100%">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="permission in availablePermissions" :key="permission.id">
                      <td>{{permission.id}}</td>
                      <td>{{permission.name}}</td>
                      <td>
                        <button type="button" @click="assignPermission(permission)" class="btn btn-light"><i class="bx bx-right-arrow me-0"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <p style="font-weight: bold;">Assigned Permissions</p>
                <table id="example" class="table table-hover" style="width:100%">
                  <thead>
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="permission in permissionGroup.permissions" :key="permission.id">
                      <td>
                        <button type="button" @click="removePermission(permission)" class="btn btn-light"><i class="bx bx-left-arrow me-0"></i></button>
                      </td>
                      <td>{{permission.id}}</td>
                      <td>{{permission.name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'admin.management.permissiongroups.view',
  props: ['id'],
  data(){
    return {
      permissionGroup: {
        id: -1,
        name: '',
        description: '',
        permissions: []
      },
      availablePermissions: []
    }
  },
  mounted(){
    this.loadPermissionGroup();
    this.loadAvailablePermissions();
  },
  methods: {
    loadPermissionGroup(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/permissiongroups/${this.id}`)
      .then(response => {
        this.permissionGroup = response.data.permissionGroup;
      })
      .catch(error => {
        this.$error("Failed to load permission group.", error);
      })
    },
    loadAvailablePermissions(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/permissions/list?available=true`)
      .then(response => {
        this.availablePermissions = response.data.permissions;
      })
      .catch(error => {
        this.$error("Failed to load available permissions.", error);
      })
    },
    assignPermission(permission){
      axios.put(`${process.env.VUE_APP_API_URL}/v1/permissiongroups/${this.id}/permissions`, {
        permission_id: permission.id
      })
      .then(() => {
        this.availablePermissions = this.availablePermissions.filter((p) => {
          return p.id !== permission.id;
        });
        this.permissionGroup.permissions.push(permission);
      })
      .catch(error => {
        this.$error("Failed to assign permission.", error);
      })
    },
    removePermission(permission){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/permissiongroups/${this.id}/permissions`, {
        data: {
          permission_id: permission.id
        }
      })
      .then(() => {
        this.permissionGroup.permissions = this.permissionGroup.permissions.filter((p) => {
          return p.id !== permission.id;
        });
        this.availablePermissions.push(permission);
      })
      .catch(error => {
        this.$error("Failed to remove permission.", error);
      })
    }
  }
}
</script>